<template>
  <!--<div class="flex md:h-screen md:overflow-hidden">-->
  <div class="flex">
    <div
      v-if="loggedInUser"
      :class="[
        'bg-white fixed top-0 bottom-0 h-full z-40 md:z-30',
        displaySidebar ? 'border-r border-gray md:border-none' : null,
        displayNotifications ? 'border-r border-gray' : null
      ]"
    >
      <div :class="['w-64 md:flex flex-col h-full', !displaySidebar ? 'hidden' : 'flex']">
        <div class="flex flex-1 flex-col h-0 lg:pt-2">
          <div class="flex flex-1 flex-col pb-4 overflow-y-auto">
            <div class="flex flex-shrink-0 items-center px-4 h-20">
              <RouterLink
                to="/"
                title="Go to Home"
                :class="[
                  'text-black h-12 w-12 items-center justify-center lg:hover:text-orange-500 transition-colors duration-300 ease-in-out',
                  !displaySidebar ? 'flex' : 'hidden md:flex'
                ]"
              >
                <span class="sr-only">Go to Home</span>

                <AtomLogo />
              </RouterLink>
              <button
                class="
                  group
                  lg:hover:text-orange-500
                  hidden
                  flex-shrink-0
                  items-center
                  justify-center
                  ml-auto
                  w-10
                  h-10
                  text-sm
                  font-medium
                  border border-gray
                  rounded-full
                  focus:outline-none
                  overflow-hidden
                  transform
                  transition
                  duration-150
                  ease-in-out
                  md:flex
                "
                :class="[displayNotifications ? 'text-orange-500' : 'text-black']"
                :title="[displayNotifications ? 'Close notifications' : 'Show notifications']"
                @click="notificationsToggle"
              >
                <span class="sr-only">Toggle Notifications</span>
                <BaseIcon class="w-10 transform transition-all duration-150 ease-in-out">
                  <AlertIcon />
                </BaseIcon>
              </button>
            </div>
            <nav class="flex-1 pt-4 md:pt-0">
              <ul>
                <li v-for="link in primaryNavigation" :key="link.url">
                  <RouterLink
                    :to="link.url"
                    class="
                      group
                      lg:hover:text-orange-500
                      flex
                      items-center
                      px-4
                      h-12
                      text-black text-lg
                      font-bold
                      transition-colors
                      duration-150
                      ease-in-out
                    "
                    active-class="text-orange-500"
                    :title="`Go to ${link.title}`"
                  >
                    <span class="flex items-center w-full h-full" @click="sidebarToggle">
                      <BaseIcon class="mr-2 w-7 h-7" :icon-name="link.title">
                        <component :is="link.icon" />
                      </BaseIcon>
                      {{ link.title }}
                    </span>
                  </RouterLink>
                </li>
              </ul>
            </nav>
          </div>
          <div v-if="loggedInUser" class="relative flex flex-col flex-shrink-0 items-center justify-between px-4">
            <div v-if="showAccountMenu" class="absolute z-20 bottom-16 left-0 p-4 w-full bg-white">
              <div class="relative grid gap-4">
                <RouterLink
                  to="/account/settings"
                  class="lg:hover:text-orange-500 flex items-center -m-3 p-3 transition duration-150 ease-in-out"
                >
                  <BaseIcon class="mr-1 w-7 h-7" icon-name="Settings">
                    <SettingsIcon />
                  </BaseIcon>
                  <p class="text-md font-bold">Settings</p>
                </RouterLink>
                <button
                  class="lg:hover:text-orange-500 flex items-center -m-3 p-3 transition duration-150 ease-in-out"
                  @click="signOut"
                >
                  <BaseIcon class="mr-1 w-7 h-7" icon-name="Sign out">
                    <SignOutIcon />
                  </BaseIcon>
                  <p class="text-md font-bold">Sign out</p>
                </button>
              </div>
            </div>

            <div class="flex items-center w-full">
              <RouterLink
                :to="profileUrl"
                class="
                  group
                  lg:hover:text-orange-500
                  flex flex-1
                  py-4
                  w-full
                  text-black
                  transition-colors
                  duration-150
                  ease-in-out
                "
                active-class="text-orange-500"
                title="View your profile"
              >
                <div class="flex items-center">
                  <div v-if="coverPhoto" class="mr-4">
                    <img
                      class="inline-block w-8 h-8 rounded-full"
                      :src="coverPhoto"
                      :alt="loggedInUser.username"
                      height="32"
                      width="32"
                    />
                  </div>
                  <div v-else class="mr-4 w-8 h-8 bg-gray rounded-full"></div>
                  <div>
                    <p class="pr-2 w-32 text-lg font-bold truncate">
                      <template v-if="name">{{ loggedInUser.username }}</template>
                      <template v-else>View profile</template>
                    </p>
                  </div>
                </div>
              </RouterLink>
              <button
                v-if="name"
                :class="[
                  'w-10 h-10 rounded-full border border-gray bg-transparent flex items-center justify-center lg:hover:bg-gray shadow-none focus:outline-none focus:ring-0 focus:bg-gray transition-colors duration-150 ease-in-out',
                  showAccountMenu ? 'bg-gray' : 'bg-white'
                ]"
                title="Toggle account menu"
                @click.prevent="toggleAccountMenu()"
              >
                <BaseIcon class="w-7 h-7">
                  <DotsIcon />
                </BaseIcon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-1 flex-col w-0" :class="[loggedInUser ? 'md:ml-64' : null]">
      <div
        v-if="loggedInUser"
        :class="[
          'h-20 w-full px-2 py-4 flex items-center border-b border-gray md:hidden fixed md:relative bg-white z-40',
          displaySidebar ? 'justify-end' : 'justify-between'
        ]"
      >
        <div class="flex items-center">
          <button
            :class="[
              'h-12 w-8 inline-flex items-center justify-center text-black',
              displaySidebar ? 'absolute left-2 z-40' : null
            ]"
            title="Menu"
            @click.prevent="sidebarToggle"
          >
            <span class="sr-only">Toggle Menu</span>
            <BaseIcon v-if="!displaySidebar" class="w-7 h-7" icon-name="menu">
              <MenuIcon />
            </BaseIcon>
            <BaseIcon v-else icon-name="menu">
              <CloseIcon />
            </BaseIcon>
          </button>
          <button
            class="
              group
              lg:hover:bg-gray
              flex flex-shrink-0
              items-center
              justify-center
              ml-auto
              w-10
              h-10
              text-black text-sm
              font-medium
              rounded-full
              focus:outline-none
              overflow-hidden
              transform
              transition
              duration-150
              ease-in-out
            "
            title="Toggle notifications"
            @click="notificationsToggle"
          >
            <span class="sr-only">Toggle Notifications</span>
            <BaseIcon class="w-7 h-7 transform transition-all duration-150 ease-in-out">
              <AlertIcon v-if="!displayNotifications" />
              <CloseIcon v-else />
            </BaseIcon>
          </button>
        </div>
        <RouterLink
          to="/"
          title="Home"
          class="
            absolute
            inset-x-1/2
            inline-flex
            items-center
            justify-center
            w-12
            h-12
            text-black
            transform
            -translate-x-6
          "
        >
          <span class="sr-only">Go to Home</span>
          <AtomLogo />
        </RouterLink>
        <ul class="inline-flex">
          <li v-for="link in globalNavigation" :key="link.url">
            <RouterLink
              :to="link.url"
              class="inline-flex items-center justify-center w-8 h-12"
              active-class="text-orange-500"
            >
              <span class="sr-only">Go to {{ link.title }}</span>
              <BaseIcon :icon-name="link.title" class="w-7 h-7">
                <component :is="link.icon" />
              </BaseIcon>
            </RouterLink>
          </li>
        </ul>
      </div>
      <main
        ref="contentContainer"
        class="relative z-0 flex-1 pt-20 focus:outline-none overflow-y-auto md:pt-0"
        tabindex="0"
      >
        <div class="flex flex-col w-full min-h-fs md:min-h-screen">
          <div
            :class="[
              'mx-auto w-full min-h-fs md:min-h-screen lg:mb-0',
              loggedInUser
                ? isFullWidth
                  ? 'md:border-gray md:border-l'
                  : 'md:border-gray md:border-l max-w-screen-2xl 2xl:border-r'
                : isFullWidth
                ? ''
                : '2xl:border-gray 2xl:border-l max-w-screen-2xl 2xl:border-r'
            ]"
          >
            <AtomLoading v-if="isLoading" />
            <Transition
              enter-active-class="transition ease-out duration-300"
              enter-from-class="opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="transition ease-in duration-300"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
              appear
            >
              <slot />
            </Transition>
          </div>
        </div>
        <OrganismFooter v-if="!loggedInUser" class="border-t border-gray" />
      </main>
    </div>
    <Transition
      enter-active-class="transform transition ease-out duration-150"
      enter-from-class="-translate-x-full"
      enter-to-class="translate-x-0"
      leave-active-class="transform transition ease-in duration-150"
      leave-from-class="translate-x-0"
      leave-to-class="-translate-x-full"
      appear
    >
      <OrganismNotifications v-if="displayNotifications && !isLoading" />
    </Transition>
    <div v-if="displayNotifications" class="fixed z-10 inset-0" @click.prevent="notificationsToggle"></div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    provide: function () {
      return {
        sidebar: this
      }
    },
    data() {
      return {
        scrollTriggered: false,
        showAccountMenu: true,
        globalNavigation: [
          {
            title: 'Events',
            url: '/events',
            icon: 'EventIcon'
          },
          {
            title: 'Dashboard',
            url: '/dashboard',
            icon: 'DashboardIcon'
          }
        ],
        primaryNavigation: [
          {
            title: 'Home',
            url: '/home',
            icon: 'HomeIcon'
          },
          {
            title: 'Dashboard',
            url: '/dashboard',
            icon: 'DashboardIcon'
          },
          {
            title: 'Events',
            url: '/events',
            icon: 'EventIcon'
          },
          {
            title: 'Albums',
            url: '/albums',
            icon: 'PhotographIcon'
          },
          {
            title: 'Videos',
            url: '/videos',
            icon: 'VideoIcon'
          },
          {
            title: 'Lap times',
            url: '/lap-times',
            icon: 'LeaderboardIcon'
          },
          {
            title: 'Members',
            url: '/members',
            icon: 'MembersIcon'
          }
        ]
      }
    },
    computed: {
      ...mapGetters({
        loggedInUser: 'auth/getLoggedInUser',
        isLoading: 'getLoadingStatus',
        displayNotifications: 'getNotificationsDisplay',
        displaySidebar: 'getSidebarDisplay',
        isBottom: 'getIsBottom',
        isTrackingScrollPos: 'getIsTrackingScrollPos'
      }),
      isFullWidth() {
        return this.$route.meta.fullWidth
      },
      name() {
        const user = this.loggedInUser

        return user.first_name ? `${user.first_name} ${user.last_name}` : false
      },
      pageName() {
        return this.$route.name
      },
      profileUrl() {
        const user = this.loggedInUser

        return `/${this.loggedInUser.username}`
      },
      coverPhoto() {
        const user = this.loggedInUser

        return user.cover_photo
          ? `${process.env.VUE_APP_IMGIX}/users/${user.id}/${user.cover_photo}?w=64&h=64&fit=crop`
          : null
      }
    },
    created() {
      if (this.loggedInUser && this.loggedInUser.first_name) this.showAccountMenu = false
    },
    mounted() {
      window.addEventListener('scroll', this.checkScrollPos)
    },
    unmounted() {
      window.removeEventListener('scroll', this.checkScrollPos)
    },
    methods: {
      signOut() {
        this.$store.dispatch('auth/signOut')
      },
      toggleAccountMenu() {
        this.showAccountMenu = !this.showAccountMenu
      },
      notificationsToggle() {
        this.$store.dispatch('setNotificationsDisplay', !this.displayNotifications)
      },
      sidebarToggle() {
        this.$store.dispatch('setSidebarDisplay', !this.displaySidebar)
      },
      async checkScrollPos() {
        if (!this.isTrackingScrollPos) return

        const timeToLoad = (window.scrollY + window.innerHeight) * 2 >= document.documentElement.scrollHeight

        if (timeToLoad) this.$store.dispatch('setIsBottom', true)
      }
    }
  }
</script>
