import { createApp } from 'vue'
import { Amplify } from 'aws-amplify'
import gtag from 'vue-gtag-next'

import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import '@/assets/css/main.css'
import AWSConfig from '@/aws-exports'
import axios from 'axios'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'

import AlertIcon from '@/components/icons/AlertIcon.vue'
import ArrowIcon from '@/components/icons/ArrowIcon.vue'
import BaseIcon from '@/components/atoms/BaseIcon.vue'
import BrandIcon from '@/components/icons/BrandIcon.vue'
import ChevronRightIcon from '@/components/icons/ChevronRightIcon.vue'
import CheckIcon from '@/components/icons/CheckIcon.vue'
import CircuitIcon from '@/components/icons/CircuitIcon.vue'
import ClockIcon from '@/components/icons/ClockIcon.vue'
import CloseIcon from '@/components/icons/CloseIcon.vue'
import CollectionIcon from '@/components/icons/CollectionIcon.vue'
import CommentIcon from '@/components/icons/CommentIcon.vue'
import CrossIcon from '@/components/icons/CrossIcon.vue'
import DashboardIcon from '@/components/icons/DashboardIcon.vue'
import DeleteIcon from '@/components/icons/DeleteIcon.vue'
import DotsIcon from '@/components/icons/DotsIcon.vue'
import EditIcon from '@/components/icons/EditIcon.vue'
import EventIcon from '@/components/icons/EventIcon.vue'
import ExternalIcon from '@/components/icons/ExternalIcon.vue'
import FavIcon from '@/components/icons/FavIcon.vue'
import FeedIcon from '@/components/icons/FeedIcon.vue'
import HomeIcon from '@/components/icons/HomeIcon.vue'
import LeaderboardIcon from '@/components/icons/LeaderboardIcon.vue'
import LikeIcon from '@/components/icons/LikeIcon.vue'
import LoveIcon from '@/components/icons/LoveIcon.vue'
import MaximizeIcon from '@/components/icons/MaximizeIcon.vue'
import MembersIcon from '@/components/icons/MembersIcon.vue'
import MessageIcon from '@/components/icons/MessageIcon.vue'
import MinimizeIcon from '@/components/icons/MinimizeIcon.vue'
import MenuIcon from '@/components/icons/MenuIcon.vue'
import OrganiserIcon from '@/components/icons/OrganiserIcon.vue'
import PersonIcon from '@/components/icons/PersonIcon.vue'
import PlayIcon from '@/components/icons/PlayIcon.vue'
import PlusIcon from '@/components/icons/PlusIcon.vue'
import PhotographIcon from '@/components/icons/PhotographIcon.vue'
import ReportIcon from '@/components/icons/ReportIcon.vue'
import SearchIcon from '@/components/icons/SearchIcon.vue'
import SettingsIcon from '@/components/icons/SettingsIcon.vue'
import SignOutIcon from '@/components/icons/SignOutIcon.vue'
import UserAddIcon from '@/components/icons/UserAddIcon.vue'
import TagIcon from '@/components/icons/TagIcon.vue'
import UserRemoveIcon from '@/components/icons/UserRemoveIcon.vue'
import UploadIcon from '@/components/icons/UploadIcon.vue'
import VideoIcon from '@/components/icons/VideoIcon.vue'
import WeatherIcon from '@/components/icons/WeatherIcon.vue'

import FacebookIcon from '@/components/icons/FacebookIcon.vue'
import InstagramIcon from '@/components/icons/InstagramIcon.vue'
import TwitterIcon from '@/components/icons/TwitterIcon.vue'

import OrganismAlbums from '@/components/organisms/Albums.vue'
import OrganismCars from '@/components/organisms/Cars.vue'
import OrganismCentered from '@/components/organisms/Centered.vue'
import OrganismCircuitLayouts from '@/components/organisms/CircuitLayouts.vue'
import OrganismCircuits from '@/components/organisms/Circuits.vue'
import OrganismComments from '@/components/organisms/Comments.vue'
import OrganismDialog from '@/components/organisms/Dialog.vue'
import OrganismEvents from '@/components/organisms/Events.vue'
import OrganismFooter from '@/components/organisms/Footer.vue'
import OrganismHeader from '@/components/organisms/Header.vue'
import OrganismMedia from '@/components/organisms/Media.vue'
import OrganismMembers from '@/components/organisms/Members.vue'
import OrganismOrganisers from '@/components/organisms/Organisers.vue'
import OrganismOverlay from '@/components/organisms/Overlay.vue'
import OrganismNotifications from '@/components/organisms/Notifications.vue'
import OrganismPanel from '@/components/organisms/Panel.vue'
import OrganismVideos from '@/components/organisms/Videos.vue'

import MoleculeAlbum from '@/components/molecules/Album.vue'
import MoleculeAuthForm from '@/components/molecules/AuthForm.vue'
import MoleculeBanner from '@/components/molecules/Banner.vue'
import MoleculeCircuit from '@/components/molecules/Circuit.vue'
import MoleculeCircuitLayout from '@/components/molecules/CircuitLayout.vue'
import MoleculeCover from '@/components/molecules/Cover.vue'
import MoleculeComments from '@/components/molecules/Comments.vue'
import MoleculeTabHeader from '@/components/molecules/TabHeader'
import MoleculeEventList from '@/components/molecules/EventList.vue'
import MoleculeFeed from '@/components/molecules/Feed.vue'
import MoleculeForm from '@/components/molecules/Form.vue'
import MoleculeFilters from '@/components/molecules/Filters'
import MoleculeHero from '@/components/molecules/Hero.vue'
import MoleculeLapTimes from '@/components/molecules/LapTimes.vue'
import MoleculeLeaderboard from '@/components/molecules/Leaderboard.vue'
import MoleculeMember from '@/components/molecules/Member.vue'
import MoleculeOrganiser from '@/components/molecules/Organiser.vue'
import MoleculeFAQs from '@/components/molecules/FAQs.vue'
import MoleculeText from '@/components/molecules/Text.vue'
import MoleculeThreeColumns from '@/components/molecules/ThreeColumns.vue'

import AtomBrand from '@/components/atoms/Brand.vue'
import AtomCar from '@/components/atoms/Car.vue'
import AtomContact from '@/components/atoms/Contact.vue'
import AtomCTA from '@/components/atoms/CTA.vue'
import AtomDashHeader from '@/components/atoms/DashHeader.vue'
import AtomEvent from '@/components/atoms/Event.vue'
import AtomFollowUser from '@/components/atoms/FollowUser.vue'
import AtomFormattedDate from '@/components/atoms/FormattedDate'
import AtomSingleLapTime from '@/components/atoms/SingleLapTime.vue'
import AtomLogo from '@/components/atoms/Logo.vue'
import AtomLeaderboard from '@/components/atoms/Leaderboard.vue'
import AtomLoader from '@/components/atoms/Loader.vue'
import AtomLoading from '@/components/atoms/Loading.vue'
import AtomNotification from '@/components/atoms/Notification.vue'
import AtomSectionHeader from '@/components/atoms/SectionHeader.vue'
import AtomPageTitle from '@/components/atoms/PageTitle.vue'
import AtomPhotograph from '@/components/atoms/Photograph'
import AtomTable from '@/components/atoms/Table'
import AtomTime from '@/components/atoms/Time.vue'
import AtomUserIcon from '@/components/atoms/UserIcon.vue'
import AtomVideo from '@/components/atoms/Video'

import Default from '@/layouts/Default.vue'
import Landing from '@/layouts/Landing.vue'
import Narrow from '@/layouts/Narrow.vue'
import Sidebar from '@/layouts/Sidebar.vue'
import Simple from '@/layouts/Simple.vue'

const app = createApp(App)

app
  .use(gtag, {
    property: {
      id: 'G-4GM8YS0LEJ'
    }
  })
  .use(store)
  .use(router)

Amplify.configure(AWSConfig)

app.component('AlertIcon', AlertIcon)
app.component('ArrowIcon', ArrowIcon)
app.component('BaseIcon', BaseIcon)
app.component('BrandIcon', BrandIcon)
app.component('ChevronRightIcon', ChevronRightIcon)
app.component('CheckIcon', CheckIcon)
app.component('CircuitIcon', CircuitIcon)
app.component('ClockIcon', ClockIcon)
app.component('CloseIcon', CloseIcon)
app.component('CollectionIcon', CollectionIcon)
app.component('CommentIcon', CommentIcon)
app.component('CrossIcon', CrossIcon)
app.component('DashboardIcon', DashboardIcon)
app.component('DotsIcon', DotsIcon)
app.component('DeleteIcon', DeleteIcon)
app.component('EditIcon', EditIcon)
app.component('EventIcon', EventIcon)
app.component('ExternalIcon', ExternalIcon)
app.component('FavIcon', FavIcon)
app.component('FeedIcon', FeedIcon)
app.component('HomeIcon', HomeIcon)
app.component('LeaderboardIcon', LeaderboardIcon)
app.component('MaximizeIcon', MaximizeIcon)
app.component('MembersIcon', MembersIcon)
app.component('MessageIcon', MessageIcon)
app.component('MinimizeIcon', MinimizeIcon)
app.component('LikeIcon', LikeIcon)
app.component('LoveIcon', LoveIcon)
app.component('MenuIcon', MenuIcon)
app.component('OrganiserIcon', OrganiserIcon)
app.component('PersonIcon', PersonIcon)
app.component('PhotographIcon', PhotographIcon)
app.component('PlayIcon', PlayIcon)
app.component('PlusIcon', PlusIcon)
app.component('ReportIcon', ReportIcon)
app.component('SearchIcon', SearchIcon)
app.component('SettingsIcon', SettingsIcon)
app.component('SignOutIcon', SignOutIcon)
app.component('TagIcon', TagIcon)
app.component('UserAddIcon', UserAddIcon)
app.component('UserRemoveIcon', UserRemoveIcon)
app.component('UploadIcon', UploadIcon)
app.component('VideoIcon', VideoIcon)
app.component('WeatherIcon', WeatherIcon)

app.component('FacebookIcon', FacebookIcon)
app.component('InstagramIcon', InstagramIcon)
app.component('TwitterIcon', TwitterIcon)

app.component('OrganismAlbums', OrganismAlbums)
app.component('OrganismCars', OrganismCars)
app.component('OrganismCentered', OrganismCentered)
app.component('OrganismCircuits', OrganismCircuits)
app.component('OrganismComments', OrganismComments)
app.component('OrganismCircuitLayouts', OrganismCircuitLayouts)
app.component('OrganismDialog', OrganismDialog)
app.component('OrganismEvents', OrganismEvents)
app.component('OrganismFooter', OrganismFooter)
app.component('OrganismHeader', OrganismHeader)
app.component('OrganismMedia', OrganismMedia)
app.component('OrganismMembers', OrganismMembers)
app.component('OrganismNotifications', OrganismNotifications)
app.component('OrganismOrganisers', OrganismOrganisers)
app.component('OrganismOverlay', OrganismOverlay)
app.component('OrganismPanel', OrganismPanel)
app.component('OrganismVideos', OrganismVideos)

app.component('MoleculeAlbum', MoleculeAlbum)
app.component('MoleculeAuthForm', MoleculeAuthForm)
app.component('MoleculeBanner', MoleculeBanner)
app.component('MoleculeCircuit', MoleculeCircuit)
app.component('MoleculeCircuitLayout', MoleculeCircuitLayout)
app.component('MoleculeComments', MoleculeComments)
app.component('MoleculeCover', MoleculeCover)
app.component('MoleculeTabHeader', MoleculeTabHeader)
app.component('MoleculeEventList', MoleculeEventList)
app.component('MoleculeFAQs', MoleculeFAQs)
app.component('MoleculeForm', MoleculeForm)
app.component('MoleculeFeed', MoleculeFeed)
app.component('MoleculeFilters', MoleculeFilters)
app.component('MoleculeHero', MoleculeHero)
app.component('MoleculeLeaderboard', MoleculeLeaderboard)
app.component('MoleculeLapTimes', MoleculeLapTimes)
app.component('MoleculeMember', MoleculeMember)
app.component('MoleculeOrganiser', MoleculeOrganiser)
app.component('MoleculeText', MoleculeText)
app.component('MoleculeThreeColumns', MoleculeThreeColumns)

app.component('AtomBrand', AtomBrand)
app.component('AtomCar', AtomCar)
app.component('AtomContact', AtomContact)
app.component('AtomCTA', AtomCTA)
app.component('AtomDashHeader', AtomDashHeader)
app.component('AtomEvent', AtomEvent)
app.component('AtomFollowUser', AtomFollowUser)
app.component('AtomFormattedDate', AtomFormattedDate)
app.component('AtomTime', AtomTime)
app.component('AtomLeaderboard', AtomLeaderboard)
app.component('AtomLoading', AtomLoading)
app.component('AtomLoader', AtomLoader)
app.component('AtomNotification', AtomNotification)
app.component('AtomSingleLapTime', AtomSingleLapTime)
app.component('AtomLogo', AtomLogo)
app.component('AtomPhotograph', AtomPhotograph)
app.component('AtomSectionHeader', AtomSectionHeader)
app.component('AtomPageTitle', AtomPageTitle)
app.component('AtomTable', AtomTable)
app.component('AtomUserIcon', AtomUserIcon)
app.component('AtomVideo', AtomVideo)

app.component('DefaultLayout', Default)
app.component('NarrowLayout', Narrow)
app.component('SidebarLayout', Sidebar)
app.component('LandingLayout', Landing)
app.component('SimpleLayout', Simple)

app.mixin({
  methods: {
    async apiRequest(endpoint, data = {}, params = {}, headers = {}, method = 'GET', rootUrl = false) {
      try {
        const res = await axios({
          method: method,
          url: !rootUrl ? process.env.VUE_APP_API_URL + endpoint : endpoint,
          data: data,
          params: params,
          headers: headers
        })

        if (res.status !== 200) {
          const error = {
            response: {
              data: res.data ? res.data : null,
              status: res.status
            }
          }

          throw error
        }

        return res.data
      } catch (e) {
        throw e.response
      }
    },
    nth(n) {
      return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th'
    },
    fileify(text) {
      const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;'
      const to = 'aaaaaeeeeeiiiiooooouuuunc------'

      const newText = text.split('').map((letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))

      return newText
        .toString() // Cast to string
        .toLowerCase() // Convert the string to lowercase letters
        .trim() // Remove whitespace from both sides of a string
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/&/g, '-y-') // Replace & with 'and'
        .replace(/[^\w\-.]+/g, '') // Remove all non-word chars
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
    }
  }
})

app.mount('#app')
