<template>
  <div class="flex h-screen overflow-hidden">
    <div class="flex flex-1 flex-col w-0">
      <div class="relative z-0 flex-1 focus:outline-none overflow-y-auto">
        <slot></slot>
        <OrganismFooter />
      </div>
    </div>
  </div>
</template>
